<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="活动名称：">
            <el-input v-model="search_name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="平台：">
          <el-select v-model="search_type" placeholder="">
            <el-option v-for="item in platformList" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="search_status" placeholder="">
            <el-option label="全部" value=""></el-option>
            <el-option label="显示" value="1"></el-option>
            <el-option label="隐藏" value="2"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item>
            <el-button type="primary" @click="seach(1)">搜索</el-button>
            <el-button type="" @click="reset(1)">重置</el-button>
        </el-form-item>
    </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
      <el-button type="danger" size="mini" @click="del()">删除</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="informationList"
        border
        style="width: 100%;"
        @selection-change="handleSelectionChange">
         <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column label="活动名称" prop="name"></el-table-column>
<el-table-column label="活动开始时间" prop="activity_start_time">
    <template slot-scope="item">
        {{item.row.activity_start_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="活动结束时间" prop="activity_end_time">
    <template slot-scope="item">
        {{item.row.activity_end_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="曝光次数" prop="exposure_times"></el-table-column>
<el-table-column label="点击次数" prop="click_times"></el-table-column>
<el-table-column label="权重" prop="item_title">
    <template slot-scope="item">
    <div v-if="item.row.edit">
        <el-input  v-model="item.row.weight" placeholder=""></el-input>
    </div>
    <span v-else>{{item.row.weight}}</span>
    <el-button type="text" @click="updateActivityCofigWeight(item.row,2)">{{!item.row.edit?'修改':'确认'}}</el-button>
    </template>
</el-table-column>
<el-table-column label="状态">
    <template slot-scope="item">
              {{item.row.status | status}}
            </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getActivityCofig(item.row.id)">修改</el-button>
            <!-- <el-button :type="item.row.status==2?'primary':'danger'" size="mini" @click="changeStatus(item.row.id,item.row.status)"> {{item.row.status==2?'显示':'隐藏'}} </el-button> -->
            <el-button size="mini" type="danger" @click="del(item.row.id)">删除</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>

<activityDialog :showExport="dialog" @clickClose="close" :platform="platform"></activityDialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'maintenanceActivity',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                classifyList: common.act_community_gift_category,
                platformList: common.platform_list,

                dialog: false,
                informationList: [],

                search_name: '',
                search_id: '',
                search_type: '0',
                search_status: '',

                select: [],
                platform: {}
            }
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '显示'
                        break;
                    case 2:
                        return '隐藏'
                        break
                    default:
                        break;
                }
            },
            type(val) {
                let name = "";
                if (JSON.stringify(common.channel_type_list).indexOf(val) != -1) {
                    common.channel_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name;
                        }
                    });
                }
                return name;
            },
            dateTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
        },
        mounted() {
            this.getActivityCofigList()
        },
        methods: {
            handleSelectionChange(val) {
                this.select = val;
            },
            // 获取列表
            getActivityCofigList(page, size) {
                api.getActivityCofigList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    name: this.search_name,
                    platform: this.search_type,
                    status: this.search_status,
                    // category_id: this.search_category

                }, res => {
                    this.total = Number(res.data.count)
                    res.data.list.forEach(element => {
                        element.edit = false
                    });
                    this.informationList = res.data.list
                    this.loading = false
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getActivityCofigList()
            },
            currentChange(val) {
                this.page = val
                this.getActivityCofigList()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getActivityCofigList(page)
            },
            reset(page) {
                this.search_name = ''
                this.search_type = '0'
                this.search_status = ''
                this.page = page
                this.getActivityCofigList(page)
            },
            // 复制
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 获取详情
            getActivityCofig(id) {
                this.id = id
                api.getActivityCofig({
                    id: id
                }, res => {
                    this.platform = res.data
                    this.dialog = true
                })
            },
            close() {
                this.dialog = false
                this.getActivityCofigList()
            },
            add() {
                this.platform = {}
                this.dialog = true
            },
            // 状态、权限
            updateActivityCofigWeight(row, type) {
                // console.log(index)
                if (type == 2 && !row.edit) {
                    row.edit = true
                    return
                }
                let data = type == 1 ? {
                    field: 'status',
                    id: row.id,
                    value: row.status == 1 ? 2 : 1
                } : {
                    field: 'weight',
                    id: row.id,
                    value: row.weight
                }
                api.updateActivityCofigWeight(data, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })

                    this.getActivityCofigList()
                })
            },
            // 删除
            del(id) {
                let del
                if (id == undefined && this.select.length == 0) {
                    this.$message.error('请选择要删除的优惠券')
                    return false
                } else if (id != undefined) {
                    del = id
                } else {
                    del = this.select
                }
                this.$confirm('是否确认删除 ' + name, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delActivityCofig({
                        id: del
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                        this.getActivityCofigList()
                    })
                }).catch(() => {})
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>